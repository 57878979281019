import {
  AmbientLight,
  AnimationMixer,
  Box3,
  Clock,
  Color,
  DirectionalLight,
  EquirectangularReflectionMapping,
  Group,
  LinearSRGBColorSpace,
  LoopOnce,
  NoToneMapping,
  PCFShadowMap,
  PCFSoftShadowMap,
  PerspectiveCamera,
  PlaneGeometry,
  RepeatWrapping,
  Scene,
  SpotLight,
  SRGBColorSpace,
  sRGBEncoding,
  TextureLoader,
  Vector3,
  WebGLRenderer,
} from "three";

import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";

import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader.js";

import { Reflector } from "./Reflector.js";

import {
  CSS2DRenderer,
  CSS2DObject,
} from "three/examples/jsm/renderers/CSS2DRenderer.js";

import TWEEN from "@tweenjs/tween.js";

export default class MavinFurnitureKiosk {
  constructor(containerID) {
    this.setContainer(containerID);
    this.options = {
      lights: [
        {
          name: "left",
          intensity: 2,
          position: new Vector3(-4, 0.5, 0),
          type: "DirectionalLight",
          shadow: {
            bias: -0.0001,
            normalBias: 0,
            mapSize: {
              width: 8192,
              height: 8192,
            },
            radius: 1,
          },
        },
        {
          name: "front",
          intensity: 2,
          position: new Vector3(0, 0.5, 4),
          type: "DirectionalLight",
          shadow: {
            bias: -0.0001,
            normalBias: 0,
            mapSize: {
              width: 8192,
              height: 8192,
            },
            radius: 25,
            blurSamples: 25,
          },
        },
        {
          name: "back",
          intensity: 2,
          position: new Vector3(0, 0.5, -4),
          type: "DirectionalLight",
          shadow: {
            bias: -0.0001,
            normalBias: 0,
            mapSize: {
              width: 8192,
              height: 8192,
            },
            radius: 1,
          },
        },
        {
          name: "right",
          intensity: 2,
          position: new Vector3(4, 0.5, 0),
          type: "DirectionalLight",
          shadow: {
            bias: -0.00001,
            normalBias: 0,
            mapSize: {
              width: 8192,
              height: 8192,
            },
            radius: 1,
          },
        },
        {
          name: "top",
          intensity: 2,
          position: new Vector3(0, 2, 0),
          type: "DirectionalLight",
          shadow: {
            bias: -0.00001,
            normalBias: 0,
            mapSize: {
              width: 8192,
              height: 8192,
            },
            radius: 1,
          },
        },
      ],
      controls: {
        enableZoom: true,
        enablePan: false,
      },
      camera: {
        fov: 40,
      },
      renderer: {
        shadowMap: {
          enabled: true,
          type: PCFSoftShadowMap,
        },
      },
      scene: {
        background: 0x202124,
      },
    };

    this.createScene();
    this.createRenderer();
    this.createCSS2DRenderer();
    this.createCamera();
    this.createControls();
    this.createLights();
    this.loadHDR("./resources/hdr/scene.hdr", 0.3);

    this.model = new Group();
    this.css2dobjects = new Group();
    this.scene.add(this.model, this.css2dobjects);

    this.animations = [];
    this.materials = [];

    this.currentPreset;

    this.clock = new Clock();

    this.update();

    this.dracoLoader = new DRACOLoader();
    this.dracoLoader.setDecoderPath("./resources/draco/");
    this.gltfLoader = new GLTFLoader();
    this.gltfLoader.dracoLoader = this.dracoLoader;
    this.textureLoader = new TextureLoader();

    this.loadModel("./resources/model/model.glb");

    this.createMirror();

    window.addEventListener("resize", this.resize.bind(this), false);
  }

  setContainer(containerID) {
    this.container = document.getElementById(containerID);

    if (!this.container) {
      console.log(`Container with id: ${containerID} is not found`);
    }
  }

  createScene() {
    this.scene = new Scene();
    this.scene.background = new Color(
      this.options?.scene?.background || "white"
    );
  }

  createRenderer() {
    this.renderer = new WebGLRenderer({
      antialias: true,
      powerPreference: "high-performance",
      precision: "highp",
    });

    this.renderer.outputColorSpace = SRGBColorSpace;

    this.width = this.container.clientWidth || 800;
    this.height = this.container.clientHeight || 800;
    this.renderer.setSize(this.width, this.height);

    this.container.appendChild(this.renderer.domElement);

    this.isMobile = this.width <= 1366;

    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

    if (this.isMobile) {
      this.renderer.shadowMap.enabled = false;
    } else {
      this.renderer.shadowMap.enabled =
        this.options.renderer?.shadowMap?.enabled || true;
      this.renderer.shadowMap.type =
        this.options.renderer?.shadowMap?.type || PCFShadowMap;
    }

    this.renderer.toneMapping =
      this.options.renderer?.toneMapping || NoToneMapping;
    this.renderer.toneMappingExposure =
      this.options.renderer?.toneMappingExposure || 1;
  }

  createCSS2DRenderer() {
    this.css2drenderer = new CSS2DRenderer();
    this.css2drenderer.setSize(
      this.container.clientWidth,
      this.container.clientHeight
    );
    this.css2drenderer.domElement.id = "configurator-overlay";
    this.container.append(this.css2drenderer.domElement);
  }

  createCamera() {
    let fov = this.width < 992 ? 50 : this.options.camera.fov;

    this.camera = new PerspectiveCamera(
      fov,
      this.width / this.height,
      this.options.camera?.near || 0.1,
      this.options.camera?.far || 500
    );

    this.scene.add(this.camera);
  }

  createControls() {
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.enableDamping = true;
    this.controls.dampingFactor = 0.05;
    this.controls.enablePan = this.options?.controls?.enablePan;
    this.controls.enableZoom = this.options?.controls?.enableZoom;
    this.controls.enableKeys = false;
    this.controls.minDistance = 0.8;
    this.controls.maxDistance = 2.4;
    this.controls.maxPolarAngle = Math.PI / 2;
    this.controls.rotateSpeed = 0.5;
  }

  createLights() {
    if (!this.options.lights) return;
    this.options.lights.forEach((light) => {
      switch (light.type) {
        case "AmbientLight":
          let ambientLight = new AmbientLight(
            light.color || new Color(22, 22, 22),
            light.intensity || 1
          );

          ambientLight.name = light.name || "ambientLight";

          this.scene.add(ambientLight);
          break;
        case "DirectionalLight":
          let directionalLight = new DirectionalLight(
            light.color || "white",
            light.intensity || 1
          );

          directionalLight.name = light.name || "directionalLight";

          directionalLight.castShadow = (light.shadow ? true : false) || false;

          if (directionalLight.castShadow) {
            directionalLight.shadow.bias = light.shadow?.bias || 0;
            directionalLight.shadow.normalBias = light.shadow?.normalBias || 0;
            directionalLight.shadow.radius = light.shadow?.radius || 1;
            directionalLight.shadow.blurSamples =
              light.shadow?.blurSamples || 8;

            directionalLight.shadow.mapSize.width = this.isMobile
              ? 512
              : light.shadow?.mapSize?.width || 512;
            directionalLight.shadow.mapSize.height = this.isMobile
              ? 512
              : light.shadow?.mapSize?.height || 512;
            directionalLight.shadow.needsUpdate = true;
          }

          switch (light.parent) {
            case "camera":
              this.camera.add(directionalLight);
              break;
            default:
            case "scene":
              this.scene.add(directionalLight);
              break;
          }

          directionalLight.position.copy(
            light.position || new Vector3(5, 10, 7.5)
          );
          break;
        case "SpotLight":
          let spotLight = new SpotLight(
            light.color || "white",
            light.intensity || 1,
            light.distance || 0,
            light.angle || 0,
            light.penumbra || 0,
            light.decay || 1
          );

          spotLight.name = light.name || "spotLight";

          spotLight.castShadow = (light.shadow ? true : false) || false;

          if (spotLight.castShadow) {
            spotLight.shadow.bias = light.shadow?.bias || 0;
            spotLight.shadow.normalBias = light.shadow?.normalBias || 0;
            spotLight.shadow.radius = light.shadow?.radius || 1;

            spotLight.shadow.mapSize.width =
              light.shadow?.mapSize?.width || 512; //default
            spotLight.shadow.mapSize.height =
              light.shadow?.mapSize?.height || 512; //default
          }

          switch (light.parent) {
            case "camera":
              this.camera.add(spotLight);
              break;
            default:
            case "scene":
              this.scene.add(spotLight);
              break;
          }

          spotLight.position.copy(light.position || new Vector3(5, 10, 7.5));
      }
    });
  }

  resize() {
    this.width = this.container.clientWidth;
    this.height = this.container.clientHeight;
    this.camera.aspect = this.width / this.height;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(this.width, this.height);
    this.css2drenderer.setSize(this.width, this.height);
  }

  update() {
    requestAnimationFrame(this.update.bind(this));
    this.controls.update();
    this.renderer.render(this.scene, this.camera);
    this.css2drenderer.render(this.scene, this.camera);

    this.mixer?.update(this.clock.getDelta());
    TWEEN.update();
  }

  changeCameraPosition(preset) {
    let cameraPosition,
      cameraTarget,
      cameraTransition = 2000;

    TWEEN.removeAll();
    this.clearHTMLElements();

    this.controls.minDistance = 0.8;
    this.controls.maxDistance = 2.4;

    switch (preset) {
      case "360-view":
        cameraPosition = new Vector3(-1, 1, 1);
        cameraTarget = new Vector3(-0.12, 0.416, 0.084);
        this.controls.enableZoom = true;
        this.controls.enableRotate = true;
        break;
      case "options":
        cameraPosition = new Vector3(-1.4, 1.4, 1.4);
        cameraTarget = new Vector3(-0.12, 0.416, 0.084);
        this.controls.enableZoom = true;
        this.controls.enableRotate = true;
        break;
      case "side-a":
        cameraPosition = new Vector3(
          0.01395447598770369,
          0.4485619626939305,
          2.2873773727790763
        );
        cameraTarget = new Vector3(
          0.013954092748462705,
          0.44856145733097774,
          0.1007385202412796
        );
        this.controls.enableZoom = false;
        this.controls.enableRotate = false;

        setTimeout(() => {
          this.createHTMLElement(
            new Vector3(0, 0.25, 0),
            "Catalogs",
            "6 x Catalogs",
            "Catalog"
          );
          this.createHTMLElement(
            new Vector3(0.17, 0.25, 0),
            "Upholstery",
            "54 x fabric & 17 x leather samples",
            "Upholstery"
          );
          this.createHTMLElement(
            new Vector3(0, 0.1, 0),
            "distressing",
            "4 x wood distressing samples",
            "DistressBoards"
          );
          this.createHTMLElement(
            new Vector3(-0.17, 0.25, 0),
            "Hardware",
            "24 x Handle Samples",
            "Hardware"
          );
          this.createHTMLElement(
            new Vector3(-0.17, 0.1, 0),
            "Table Edges",
            "10 x table edge samples",
            "TableEdges"
          );
        }, cameraTransition);

        break;
      case "side-b":
        cameraPosition = new Vector3(
          -0.04735440661716647,
          0.4024153128266335,
          -2.4019281781369552
        );
        cameraTarget = new Vector3(
          -0.047354310750960575,
          0.4324154332280159,
          8.279690657144472e-9
        );
        this.controls.enableZoom = false;
        this.controls.enableRotate = false;

        setTimeout(() => {
          this.createHTMLElement(
            new Vector3(0, 0.25, 0),
            "Wood samples",
            "100 x Wood Finish Samples",
            "WoodenSample"
          );
        }, cameraTransition);

        break;
      case "side-c":
        cameraPosition = new Vector3(
          2.181758335969085,
          0.37663064152002346,
          -0.004311227799478688
        );
        cameraTarget = new Vector3(
          0.26679410710064033,
          0.3766307309269905,
          -0.004310833371853874
        );
        this.controls.enableZoom = false;
        this.controls.enableRotate = false;

        setTimeout(() => {
          this.createHTMLElement(
            new Vector3(0, 0.25, 0),
            "Table Legs",
            "15 x Table Leg Samples",
            "TableLegs"
          );
        }, cameraTransition);

        break;
      case "side-d":
        cameraPosition = new Vector3(
          -2.392920837576053,
          0.45036508468766306,
          0.0032261251223328195
        );
        cameraTarget = new Vector3(
          0.007078483476048197,
          0.4485614597797394,
          0.0033028495963662863
        );
        this.controls.enableZoom = false;
        this.controls.enableRotate = false;

        setTimeout(() => {
          this.createURLElement(
            new Vector3(0, 0.438, -0.11),
            "Configurator",
            "Configurator",
            "https://mavin.jolacgi.com"
          );

          this.createVideoElement(
            new Vector3(0, 0.438, 0.11),
            "Play Video",
            "Play Video",
            '<iframe src="https://player.vimeo.com/video/827301204?h=ae95ce2a19&amp;badge=0&amp;autopause=0&amp;quality_selector=1&amp;progress_bar=1&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="From Forest To Final"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>'
          );
        }, cameraTransition);
        break;
    }

    for (const animation of this.animations) {
      if (
        animation.timeScale === 1 &&
        animation._clip.name !== "Intro" &&
        animation._clip.name !== "TransitionAB" &&
        animation._clip.name !== "TransitionCD"
      ) {
        animation.paused = false;
        animation.enabled = true;
        animation.timeScale *= -2;
        animation.play();
        setTimeout(() => {
          animation.timeScale = -1;
        }, animation._clip.duration);
      }
    }

    if (
      (this.currentPreset === "side-b" && preset === "side-a") ||
      (this.currentPreset === "side-a" && preset === "side-b")
    ) {
      this.currentPreset === "side-a" && preset === "side-b"
        ? this.playAnimation("TransitionAB", -1)
        : this.playAnimation("TransitionAB", 1);
    } else if (
      (this.currentPreset === "side-c" && preset === "side-d") ||
      (this.currentPreset === "side-d" && preset === "side-c")
    ) {
      this.currentPreset === "side-c" && preset === "side-d"
        ? this.playAnimation("TransitionCD", -1)
        : this.playAnimation("TransitionCD", 1);
    } else {
      new TWEEN.Tween(this.camera.position)
        .to(cameraPosition, cameraTransition)
        .start()
        .onComplete(() => {
          if (preset !== "360-view" && preset !== "options") {
            this.controls.minDistance = 0;
            this.controls.maxDistance = 500;
          }
        });
      new TWEEN.Tween(this.controls.target)
        .to(cameraTarget, cameraTransition)
        .start();
    }

    this.currentPreset = preset;
  }

  updateCameraPosition() {
    this.camera.position.set(
      -1.146388686404201,
      1.0971488555214974,
      1.1523773144687937
    );
    this.controls.target = new Vector3(
      -0.12067802189304844,
      0.4164297115057707,
      0.08407021380804652
    );
  }

  loadHDR(input, intensity = 1) {
    this.envMapIntensity = intensity;
    let rgbeLoader = new RGBELoader();

    rgbeLoader.load(input, (texture) => {
      texture.mapping = EquirectangularReflectionMapping;
      this.scene.background = texture;
    });
  }

  loadModel(input) {
    this.gltfLoader.load(input, async (model) => {
      await this.loadTextures();

      model.scene.traverse((child) => {
        child.castShadow = true;
        child.receiveShadow = true;

        if (child.material) {
          let material = this.materials.find(
            (x) => x.name === child.material.name
          );

          if (material) {
            if (material.map) {
              child.material.map = material.map;
              child.material.map.colorSpace = SRGBColorSpace;
              child.material.map.wrapS = RepeatWrapping;
              child.material.map.wrapT = RepeatWrapping;
              child.material.map.flipY = false;
              child.material.map.anisotropy =
                this.renderer.capabilities.getMaxAnisotropy();
            }

            if (material.normalMap) {
              child.material.normalMap = material.normalMap;
              child.material.normalMap.wrapS = RepeatWrapping;
              child.material.normalMap.wrapT = RepeatWrapping;
              child.material.normalMap.flipY = false;
              child.material.normalMap.anisotropy =
                this.renderer.capabilities.getMaxAnisotropy();
            }

            if (material.roughnessMap) {
              child.material.roughnessMap = material.roughnessMap;
              child.material.roughnessMap.wrapS = RepeatWrapping;
              child.material.roughnessMap.wrapT = RepeatWrapping;
              child.material.roughnessMap.flipY = false;
              child.material.roughnessMap.anisotropy =
                this.renderer.capabilities.getMaxAnisotropy();

              child.material.metalnessMap = child.material.roughnessMap;
            }

            child.material.needsUpdate = true;
          }
        }
      });

      this.mixer = new AnimationMixer(model);

      model.animations.forEach((animation) => {
        let anim = this.mixer.clipAction(animation, model.scene);
        anim.clampWhenFinished = true;
        anim.paused = false;
        anim.setLoop(LoopOnce);
        anim.timeScale = -1;
        this.animations.push(anim);
      });

      model.scene.getObjectByName("main").scale.set(0, 0, 0);

      this.model.add(model.scene);

      this.updateCameraPosition();
      this.playAnimation("Intro");
    });
  }

  async loadTextures() {
    let promises = [];
    let urls = [
      "CatalogMavin_color.jpg",
      "catalog_textures-12.jpg",
      "DistressBoard_Color.jpg",
      "DistressBoard_Normal.jpg",
      "DistressBoard_Roughness.jpg",
      "TableEdges_Color.jpg",
      "UpholsteryFabrics_color.jpg",
      "UpholsteryFabrics_color_Normal.jpg",
      "UpholsteryFabrics_color_Roughness.jpg",
      "UpholsteryLeathers_color.jpg",
      "UpholsteryLeathers_color_Normal.jpg",
      "UpholsteryLeathers_color_Roughness.jpg",
      "WoodMaple_color.jpg",
      "WoodMaple_Roughness.jpg",
      "WoodSamples_color.jpg",
      "WoodSamples_Roughness.jpg",
      "Aluminium_Roughness.jpg",
      "WoodSamplesMain.jpg",
    ];

    for (const url of urls) {
      let promise = this.loadTexture(`./resources/textures/model/${url}`);
      promises.push(promise);
    }

    let materials = await Promise.all(promises);

    let catalogMavin = {
      name: "CatalogMavin",
      map: materials[0],
    };

    let catalogTextures = {
      name: "catalog_textures-12",
      map: materials[1],
    };

    let distressBoard = {
      name: "DistressBoard",
      map: materials[2],
      normalMap: materials[3],
      roughnessMap: materials[4],
    };

    let tableEdges = {
      name: "TableEdges",
      map: materials[5],
    };

    let upholsteryFabrics = {
      name: "UpholsteryFabrics",
      map: materials[6],
      normalMap: materials[7],
      roughnessMap: materials[8],
    };

    let upholsteryLeathers = {
      name: "UpholsteryLeathers",
      map: materials[9],
      normalMap: materials[10],
      roughnessMap: materials[11],
    };

    let woodMaple = {
      name: "WoodMaple",
      map: materials[12],
      roughnessMap: materials[13],
    };

    let woodSamples = {
      name: "WoodSamples",
      map: materials[14],
      roughnessMap: materials[15],
    };

    let aluminium = {
      name: "Aluminium",
      roughnessMap: materials[16],
    };

    let woodSamplesMain = {
      name: "WoodSamplesMain",
      map: materials[17],
    };

    this.materials.push(
      catalogMavin,
      catalogTextures,
      distressBoard,
      tableEdges,
      upholsteryFabrics,
      upholsteryLeathers,
      woodMaple,
      woodSamples,
      aluminium,
      woodSamplesMain
    );
  }

  loadTexture(url) {
    return new Promise((resolve) => {
      this.textureLoader.load(url, (result) => resolve(result));
    });
  }

  playAnimation(animationName, timeScale = undefined) {
    let animation = this.animations.find((x) => x._clip.name === animationName);

    if (this.width < 992) {
      let mobileAnimation = this.animations.find(
        (x) => x._clip.name === animationName + "_mobile"
      );
      if (mobileAnimation) animation = mobileAnimation;
    }

    let camera = this.model.getObjectByName(`${animationName}_Camera`);
    let target = this.model.getObjectByName(`${animationName}_Camera_Target`);

    if (
      animation._clip.name.includes("Transition") &&
      animation.timeScale === timeScale
    )
      timeScale === -1 ? (animation.time = animation._clip.duration) : 0;

    animation.timeScale =
      timeScale !== undefined ? timeScale : animation.timeScale * -1;
    animation.paused = false;
    animation.enabled = true;
    animation.play();

    if (camera && target) {
      this.hideHTMLElements();
      new TWEEN.Tween(this.camera.position)
        .to(
          camera.getWorldPosition(new Vector3()),
          animation._clip.duration * 1000
        )
        .onUpdate(() => {
          this.camera.position.copy(camera.getWorldPosition(new Vector3()));
          this.controls.target = target.getWorldPosition(new Vector3());
        })
        .onComplete(() => {
          if (
            animationName === "TransitionAB" ||
            animationName === "TransitionCD"
          ) {
            this.showHTMLElements();
          } else {
            if (animation.timeScale === -1) {
              this.showHTMLElements();
            } else {
              document
                .querySelector(".accessories-overlay-container")
                .classList.remove("display-none");
            }
          }
        })
        .start();
    }
  }

  hideHTMLElements() {
    this.css2drenderer.domElement.style.visibility = "hidden";
  }

  showHTMLElements() {
    this.css2drenderer.domElement.style.visibility = "visible";
  }

  clearHTMLElements() {
    this.css2drenderer.domElement.innerHTML = "";
    this.css2dobjects.clear();
    this.showHTMLElements();
  }

  createHTMLElement(position, title, content, animation) {
    let container = document.createElement("div");
    container.classList.add("accessory-button-container");

    let button = document.createElement("div");
    button.classList.add("accessory-button");
    button.onclick = () => {
      document.querySelector(
        ".accessory-title"
      ).innerHTML = `${title}<span>${content}</span>`;
      this.playAnimation(animation);

      document.querySelector(".close-button").onclick = () => {
        document
          .querySelector(".accessories-overlay-container")
          .classList.add("display-none");
        this.playAnimation(animation);
      };
    };

    let description = document.createElement("div");
    description.classList.add("accessory-description");
    description.innerHTML = title;

    container.append(button, description);

    this.css2drenderer.domElement.append(container);

    let css2delement = new CSS2DObject(container);
    css2delement.position.copy(position);
    this.css2dobjects.add(css2delement);
  }

  createVideoElement(position, title, content, video) {
    let container = document.createElement("div");
    container.classList.add("accessory-button-container");

    let button = document.createElement("div");
    button.classList.add("accessory-button");
    button.onclick = () => {
      this.hideHTMLElements();
      document
        .querySelector(".accessories-overlay-container")
        .classList.remove("display-none");
      document
        .querySelector(".video-container")
        .classList.remove("display-none");

      document.querySelector(".accessory-title").innerHTML = content;
      document.querySelector(".video-container").innerHTML = video;

      document.querySelector(".close-button").onclick = () => {
        this.showHTMLElements();
        document
          .querySelector(".accessories-overlay-container")
          .classList.add("display-none");
        document
          .querySelector(".video-container")
          .classList.add("display-none");
      };
    };

    let description = document.createElement("div");
    description.classList.add("accessory-description");
    description.innerHTML = title;

    container.append(button, description);

    this.css2drenderer.domElement.append(container);

    let css2delement = new CSS2DObject(container);
    css2delement.position.copy(position);
    this.css2dobjects.add(css2delement);
  }

  createURLElement(position, title, content, url) {
    let container = document.createElement("div");
    container.classList.add("accessory-button-container");

    let button = document.createElement("div");
    button.classList.add("accessory-button");
    button.onclick = () => {
      window.open(url, "_blank");
    };

    let description = document.createElement("div");
    description.classList.add("accessory-description");
    description.innerHTML = title;

    container.append(button, description);

    this.css2drenderer.domElement.append(container);

    let css2delement = new CSS2DObject(container);
    css2delement.position.copy(position);
    this.css2dobjects.add(css2delement);
  }

  createMirror() {
    let geometry = new PlaneGeometry(100, 100);
    this.mirror = new Reflector(geometry, {
      textureWidth: 2048,
      textureHeight: 2048,
      color: 0x445555,
    });
    this.mirror.rotateX(-Math.PI / 2);
    this.scene.add(this.mirror);
  }
}
